export const licitaciones = [
    {
        "anio" : "2017",
        "licitacionesLista" : [
            {
                "nombre" : "1.- Licitación Pública Nacional N°. SITMAH/LPN/SL/IPRITC1ZMP/001/2017",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "1.1 Convocatoria servicio de limpieza 2017",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/1_SITMAHLPNSLIPRITC1ZMP0012017/Convocatoria-servicio-de-limpieza-2017.pdf"
                    },
                    {
                        "nombre" : "1.2 BASES LIMPIEZA FINAL (OK) 2017",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/1_SITMAHLPNSLIPRITC1ZMP0012017/BASES-LIMPIEZA-FINAL-OK-2017.pdf"
                    },
                    {
                        "nombre" : "1.3 Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/1_SITMAHLPNSLIPRITC1ZMP0012017/3_Untitled.pdf"
                    },
                    {
                        "nombre" : "1.4 SEGUNDA CONVOCATORIA BASES LIMPIEZA FINAL 2017",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/1_SITMAHLPNSLIPRITC1ZMP0012017/4_SEGUNDA-CONVOCATORIA-BASES-LIMPIEZA-FINAL-2017.pdf"
                    }, 
                    {
                        "nombre" : "1.5 Segunda Convocatoria servicio de limpieza 2017",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/1_SITMAHLPNSLIPRITC1ZMP0012017/5_Segunda-Convocatoria-servicio-de-limpieza-2017.pdf"
                    }, 
                    {
                        "nombre" : "1.6 Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/1_SITMAHLPNSLIPRITC1ZMP0012017/6_Junta-de-Aclaraciones.pdf"
                    },
                    {
                        "nombre" : "1.7 Segunda Convocatoria servicio de limpieza sep-dic 2017 (1)",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/1_SITMAHLPNSLIPRITC1ZMP0012017/7_Segunda-Convocatoria-servicio-de-limpieza-sep-dic-2017-1.pdf"
                    },
                    {
                        "nombre" : "1.8 SEGUNDA CONVOCATORIA BASES LIMPIEZA sep-dic 2017 (1)",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/1_SITMAHLPNSLIPRITC1ZMP0012017/8_SEGUNDA-CONVOCATORIA-BASES-LIMPIEZA-sep-dic-2017-1.pdf"
                    },
                    
                ]
            },
            {
                "nombre" : "2.- Licitación Pública Nacional N°. SITMAH/LPN/SA/IPRITC1ZMP/003/2017",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "2.1 Bases Licitacion Aseguramineto",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/2_SITMAHLPNSAIPRITC1ZMP0032017/1_Bases-Licitacion-Aseguramineto.pdf"
                    },
                    {
                        "nombre" : "2.2 Acta de Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/2_SITMAHLPNSAIPRITC1ZMP0032017/2_Untitled.pdf"
                    },
                    
                    
                ]
            },
           
        ]
        
         
    },
    {
        "anio" : "2018",
        "licitacionesLista" : [
            {
                "nombre" : "3.- Licitación Pública Nacional N°. SITMAH/LPN/SL/001/2018",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "3.1 Convocatoria servicio de limpieza 2018",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/3_SITMAHLPNSL0012018/1_Convocatoria-servicio-de-limpieza-2018.pdf"
                    },
                    {
                        "nombre" : "3.2 BASES LIMPIEZA 2018",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/3_SITMAHLPNSL0012018/2_BASES-LIMPIEZA-2018.pdf"
                    },
                    {
                        "nombre" : "3.3 Segunda Convocatoria servicio de limpieza 2018",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/3_SITMAHLPNSL0012018/3_Segunda-Convocatoria-servicio-de-limpieza-2018.pdf"
                    },
                    {
                        "nombre" : "3.4 SEGUNDA BASES LIMPIEZA 2018",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/3_SITMAHLPNSL0012018/4_SEGUNDA-BASES-LIMPIEZA-2018.pdf"
                    },
                ]

            },
            {
                "nombre" : "4.- Invitación a cuando menos tres personas N°. SITMAH/I.C.T.P/002/2018",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "4.1 BASES INVITACION A CUANDO MENOS TRES MATERIAL DE OFICINA",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/4_SITMAHI.C.T.P0022018/1_BASES-INVITACION-A-CUANDO-MENOS-TRES-MATERIAL-DE-OFICINA.pdf"
                    },
                    {
                        "nombre" : "4.2 JUNTA DE ACLARACIONES INVITACION CUANDO MENOS TRES PERSONAS MATERIAL OFICINA",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/4_SITMAHI.C.T.P0022018/2_JUNTA-DE-ACLARACIONES-INVITACION-CUANDO-MENOS-TRES-PERSONAS-MATERIAL-OFICINA.pdf"
                    },
                    
                    
                ]
            },
            {
                "nombre" : "5.- Invitación a cuando menos tres personas N°. SITMAH/I.C.T.P/003/2018",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "5.1 BASES INVITACION A CUANDO MENOS TRES CONSUMIBLES",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/5_SITMAHI.C.T.P0032018/1_BASES-INVITACION-A-CUANDO-MENOS-TRES-CONSUMIBLES.pdf"
                    },
                    {
                        "nombre" : "5.2 JUNTA DE ACLARACIONES INVITACION A CUANDO MENOS TRES CONSUMIBLES",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/5_SITMAHI.C.T.P0032018/2_JUNTA-DE-ACLARACIONES-INVITACION-A-CUANDO-MENOS-TRES-CONSUMIBLES.pdf"
                    },
                    {
                        "nombre" : "5.3 APERTURA DE PROPOSICIONES INVITACION A CUANDO MENOS TRES CONSUMIBLES",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/5_SITMAHI.C.T.P0032018/3_APERTURA-DE-PROPOSICIONES-INVITACION-A-CUANDO-MENOS-TRES-CONSUMIBLES.pdf"
                    },
                    
                    
                ]
            },
            {
                "nombre" : "6.- Licitación Pública Nacional N°. SITMAH/LPN/SA/IPRITC1ZMP/003/2018",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "6.1 Bases Licitación Aseguramiento Infraestructura",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/6_SITMAHLPNSAIPRITC1ZMP0032018/1_Bases-Licitacin-Aseguramiento-Infraestructura.pdf"
                    },
                    {
                        "nombre" : "6.2 Acta de Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/6_SITMAHLPNSAIPRITC1ZMP0032018/5_LIGHT-ilovepdf-compressed.pdf"
                    },
                    {
                        "nombre" : "6.3 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/6_SITMAHLPNSAIPRITC1ZMP0032018/6_Propuesta.pdf"
                    },
                    {
                        "nombre" : "6.4 Acto de Fallo",
                        "url" : ""
                    },
                    
                    
                ]
            },

        ]
        
    },
    {
        "anio" : "2019",
        "licitacionesLista" : [
            {
                "nombre" : "7.- Bases de licitación Publica Nacional no. SITMAH/LPN/SL/001/2019",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "7.1 BASES LIMPIEZA 2019 OK",
                        "url" : ""
                    },
                    {
                        "nombre" : "7.2 BRN3C2AF454FE49_011904",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/7_SITMAHLPNSL0012019/2_BRN3C2AF454FE49_011904.pdf"
                    },
                    {
                        "nombre" : "7.3 JUNTA DE ACLARACIONES SERV. LIMPIEZA 2019.",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/7_SITMAHLPNSL0012019/3_JUNTA-DE-ACLARACIONES-SERV.-LIMPIEZA-2019..pdf"
                    },
                    {
                        "nombre" : "7.4 acta de presentación y apertura de propuestas.",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/7_SITMAHLPNSL0012019/4_acta-de-presentacin-y-apertura-de-propuestas.pdf"
                    }
                ]
            },
            {
                "nombre" : "8.- Licitación Pública Nacional NO. SITMAH/LPN/MCPEYBI/002/2019",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "8.1 Convocatoria Toners 2019.",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/8_SITMAHLPNMCPEYBI0022019/6.1-Convocatoria-Toners-2019.pdf"
                    },
                    {
                        "nombre" : "8.2 BASES TONERS PARA LICITACIÓN 2019.",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/8_SITMAHLPNMCPEYBI0022019/2_BASES-TONERS-PARA-LICITACIN-2019.pdf"
                    },
                    {
                        "nombre" : "8.3 ACTA DE JUNTA DE ACLARACIONES.",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/8_SITMAHLPNMCPEYBI0022019/3_BRN3C2AF454FE49_015237-Richard-Escamilla.pdf"
                    },
                    {
                        "nombre" : "8.4 PUBLICACION DEL ACTA DE PRESENTACIÓN Y APERTURA DE PROPOSICIONES DE CONSUMIBLES.",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/8_SITMAHLPNMCPEYBI0022019/document2.pdf"
                    },
                    {
                        "nombre" : "8.5 ACTA CORRESPONDIENTE AL ACTO DE FALLO.",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/8_SITMAHLPNMCPEYBI0022019/SITMAH-LPN-MCPEYBI-002-2019%20-%20Richard%20Escamilla.pdf"
                    },
                    
                    
                ]
            },
            {
                "nombre" : "9.- Licitación Pública Nacional SITMAH/LPN/MO/003/2019",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "9.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/9_SITMAHLPNMO0032019/9.1ConvocatoriaMatdeOf2019.pdf"
                    },
                    {
                        "nombre" : "9.2 Bases Papeleria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/9_SITMAHLPNMO0032019/9.1BASES%20PAPELERIA%20PARA%20LICITACION%202019.pdf"
                    },
                    {
                        "nombre" : "9.3 Acta de Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/9_SITMAHLPNMO0032019/ACTA%20DE%20JUNTA%20DE%20ACLARACIONES.pdf"
                    },       
                ]
            },
            {
                "nombre" : "10.- Licitación Pública Nacional SITMAH/LPN/MO/004/2019",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "10.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/10_SITMAHLPNMO0042019/6.1%20Convocatoria%20Mat.%20de%20Of.%202019.pdf"
                    },
                    {
                        "nombre" : "10.2 Bases Papeleria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/10_SITMAHLPNMO0042019/BASES%20PAPELERIA%20PARA%20LICITACION%202019.pdf"
                    },
                    {
                        "nombre" : "10.3 Acta de Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/10_SITMAHLPNMO0042019/BRN3C2AF454FE49_021845.pdf"
                    },
                    {
                        "nombre" : "10.4 Acta Correspondiente al Acto de Fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/10_SITMAHLPNMO0042019/acta%20de%20fallo%2002019.pdf"
                    },
                    {
                        "nombre" : "10.5 Presentacion y Apertura de Proposiciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/10_SITMAHLPNMO0042019/ACTA%20DE%20APERTURA%20DE%20PROPUESTAS.pdf"
                    },
                    
                    
                ]
            },
            {
                "nombre" : "11.- Licitación Pública Nacional No. SITMAH/LPN/SA/IPRITC1ZMP/005/2019",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "11.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/11_SITMAHLPNSAIPRITC1ZMP0052019/Convocatoria%20servicio%20de%20ASEGURAMIENTO.pdf"
                    },
                    {
                        "nombre" : "11.2 Bases Seguros Ok SITMAH",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/11_SITMAHLPNSAIPRITC1ZMP0052019/BASES%20ok%20SEGUROS%20SITMAH.pdf"
                    },
                    {
                        "nombre" : "11.3 Acta de la Junta de Aclaraciones correspondiente al Proceso de contratación del servicio de Aseguramiento",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/11_SITMAHLPNSAIPRITC1ZMP0052019/ACTA%20DE%20JUNTA%20DE%20ACLARACIONES%20ASEGURAMIENTO%20%20-%20Mexican%20news.pdf"
                    },
                    {
                        "nombre" : "11.4 ACTA DE PRESENTACIÓN Y APERTURA DE PROPUESTAS",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/11_SITMAHLPNSAIPRITC1ZMP0052019/ACTA%20DE%20PRESENTACI%C3%93N%20Y%20APERTURA%20DE%20PROPUESTAS%20-%20Mexican%20news.pdf"
                    },
                    {
                        "nombre" : "11.5 ACTO DE FALLO No SITMAH-LPN-SA-IPRITC1ZMP-005-2019s",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/11_SITMAHLPNSAIPRITC1ZMP0052019/ACTO%20DE%20FALLO%20No%20SITMAH-LPN-SA-IPRITC1ZMP-005-2019.pdf"
                    },
                    
                    
                ]
            },
        ]
    },
    {
        "anio" : "2020",
        "licitacionesLista" : [
            {
                "nombre" : "12.- Convocatoria y Bases de Licitación Publica No. SITMAH/LPN/SL/001/2020",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "12.1 Convocatoria Servicio de Limpieza",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/12_SITMAH_LPN_SL_001_2020/6.1%20Convocatoria%20Servicio%20de%20Limpieza%202020.pdf"
                    },
                    {
                        "nombre" : "12.2 Bases Limpieza",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/12_SITMAH_LPN_SL_001_2020/6%20BASES%20LIMPIEZA%202020%20OK.pdf"
                    },
                    {
                        "nombre" : "12.3 Acta de Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/12_SITMAH_LPN_SL_001_2020/ACTA_DE_JUNTA_DE_ACLARACIONES_SERVICIO_DE_LIMPIEZA_2020.pdf"
                    },
                    {
                        "nombre" : "12.4 Acta de Presentación y Apertura de Proposiciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/12_SITMAH_LPN_SL_001_2020/ACTA%20DE%20PRESENTACION%20Y%20APERTURA%20SERVICIO%20DE%20LIMPIEZA%202020.pdf"
                    },
                    {
                        "nombre" : "12.5 Acta de Fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/12_SITMAH_LPN_SL_001_2020/FALLO%20SERVICIO%20DE%20LIMPIEZA%20.pdf"
                    }
                ]
            },
            {
                "nombre" : "13.- Bases de la Licitación Pública Nacional No. SITMAH/ LPN/MCPEYBI/003/2020",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "13.1 Convocatoria Consumibles",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/13_SITMAHLPNMCPEYBI0032020/6.1%20Convocatoria%20consumibles%202020.pdf"
                    },
                    {
                        "nombre" : "13.2 Bases Toners",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/13_SITMAHLPNMCPEYBI0032020/BASES%20TONERS%20PARA%20LICITACIAA%CC%83__N%202020.pdf"
                    },
                    {
                        "nombre" : "13.3 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/13_SITMAHLPNMCPEYBI0032020/Acta%20Junta%20de%20Aclaraciones%20SITMAH-LPN-MCPEYBY-003-2020.pdf"
                    },
                    {
                        "nombre" : "13.4 Acta de la Presentación y Apertura de Proposiciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/13_SITMAHLPNMCPEYBI0032020/Acta_Presentacion_y_apertura_SITMA_LPN_MCPEYBY_003_2020.pdf"
                    },
                    {
                        "nombre" : "13.5 Acta de Fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/13_SITMAHLPNMCPEYBI0032020/ACTA_DE_FALLO_CONSUMIBLES.pdf"
                    }
                ]
            },
            {
                "nombre" : "14.- Bases de la Licitación Pública Nacional NO. SITMAH/LPN/SA/IPRITC1ZMP/005/2020",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "14.1 Convocatoria Servicio de Aseguramiento",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023//licitaciones/14_SITMAHLPNSAIPRITC1ZMP0052020/Convocatoria%20servicio%20de%20ASEGURAMIENTO.pdf"
                    },
                    {
                        "nombre" : "14.2 Bases Seguros",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/14_SITMAHLPNSAIPRITC1ZMP0052020/BASES%20ok%20SEGUROS%20SITMAH.pdf"
                    },
                    {
                        "nombre" : "14.3 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/14_SITMAHLPNSAIPRITC1ZMP0052020/ACTA%20DE%20JUNTA%20DE%20ACLARACIONES%20ASEGURAMIENTO%20INFRAESTRUCTURA%202020.pdf"
                    },
                    {
                        "nombre" : "14.4 Acta de Presentación y Apertura de Proposiciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/14_SITMAHLPNSAIPRITC1ZMP0052020/ACTA%20DE%20PRESENTACION%20Y%20APERTURA%20DE%20PROPOSICIONES%20SEGUROS.pdf"
                    },
                    {
                        "nombre" : "14.5 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/14_SITMAHLPNSAIPRITC1ZMP0052020/ACTA%20DE%20FALLO%20SEGUROS.pdf"
                    }
                ]
            }
            
        ]
    },
    {
        "anio" : "2021",
        "licitacionesLista" : [
            {
                "nombre" : "15.- Bases para la Licitación Pública Nacional NO. SITMAH/LPN/SL/001/2021, de la partida 358001 (Servicio de Limpieza y Manejo de Desechos)",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "15.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/15_SITMAHLPNSL0012021/CONVOCATORIA%20A%20LPN%20NO.%20SITMAH-LPN-SL-001-2021.pdf"
                    },
                    {
                        "nombre" : "15.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/15_SITMAHLPNSL0012021/BASES%20SERVICIO%20DE%20LIMPIEZA%202021.pdf"
                    },
                    {
                        "nombre" : "15.3 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/15_SITMAHLPNSL0012021/ACTA%20DE%20JUNTA%20DE%20ACLARACIONES%20LICITACION%20NO.%20SITMAH-LPN-SL-001-2021.pdf"
                    },
                    {
                        "nombre" : "15.4 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/15_SITMAHLPNSL0012021/Acta%20de%20Presentacion%20y%20Apertura%20de%20Propuestas.pdf"
                    },
                    {
                        "nombre" : "15.5 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/15_SITMAHLPNSL0012021/ACTA_DE_FALLO_SERVICIO_DE_LIMPIEZA.pdf"
                    }
                ]
            },
            {
                "nombre" : "16.- Convocatoria Licitación Pública Nacional NO. SITMAH/LPN/SL/002/2021",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "16.1 Convocatoria y Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/15_SITMAHLPNSL0012021/CONVOCATORIA_NO._SITMAH_LPN_SL_002_2021.pdf"
                    },
                    {
                        "nombre" : "16.2 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/15_SITMAHLPNSL0012021/Acta_de_Junta_de_Aclaraciones.pdf"
                    },
                    {
                        "nombre" : "16.3 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/15_SITMAHLPNSL0012021/Acta_de_Presentacion_y_Apertura_de_Propuestas.pdf"
                    },
                    {
                        "nombre" : "16.4 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/15_SITMAHLPNSL0012021/ACTA_DE_FALLO_LICITACION_NO.SITMAH_LPN_SL_002_2021.pdf"
                    }
                ]
            },
            {
                "nombre" : "17.- Convocatoria Licitación Pública Nacional NO. SITMAH/LPN/SA/IPRITC1ZMP/003/2021",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "17.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023//licitaciones/15_SITMAHLPNSL0012021/CONVOCATORIA_2021.pdf"
                    },
                    {
                        "nombre" : "17.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/15_SITMAHLPNSL0012021/BASES_DE_LICITACION_PUBLICA_NACIONAL_(ASEGURAMIENTO_DE_INFRAESTRUCTURA)SITMAH.pdf"
                    },
                    {
                        "nombre" : "17.3 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/15_SITMAHLPNSL0012021/ACTA_DE_JUNTA_DE_ACLARACIONES_2021.pdf"
                    },
                    {
                        "nombre" : "17.4 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/15_SITMAHLPNSL0012021/Acta_Presentaci%C3%B3n_y_Apertura_de_Proposiciones_2021.pdfs"
                    },
                    {
                        "nombre" : "17.5 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/15_SITMAHLPNSL0012021/ACTA_DE_FALLO_2021.pdf"
                    }
                ]
            }
        ]
    },
    {
        "anio" : "2022",
        "licitacionesLista" : [
            {
                "nombre" : "18.- Publicación de Bases y Convocatoria a la Licitación Publica Nacional NO. SITMAH/LPN/SL/001/2022",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "18.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/18_SITMAHLPNSL0012022/6.1_Convocatoria_Servicio_de_Limpieza_2022.pdf"
                    },
                    {
                        "nombre" : "18.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/18_SITMAHLPNSL0012022/BASES_LIMPIEZA_2022.pdf"
                    },
                    {
                        "nombre" : "18.3 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/18_SITMAHLPNSL0012022/Acta_de_Junta_de_Aclaraciones_2022.pdf"
                    },
                    {
                        "nombre" : "18.4 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/18_SITMAHLPNSL0012022/Presentacion_y_Apertura_de_Propuestas_2022.pdf"
                    },
                    {
                        "nombre" : "18.5 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/18_SITMAHLPNSL0012022/ACTA_DE_FALLO_LICITACION_PUBLICA_NACIONAL_2022"
                    }
                ]
            },
            {
                "nombre" : "19.- Publicación de Bases y Convocatoria a la Licitación Publica Nacional NO. SITMAH/LPN/SL/002/2022",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "19.- Publicación de Bases y Convocatoria a la Licitación Publica Nacional NO. SITMAH/LPN/SL/002/2022",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/19SITMAHLPNSL022022/CONVOCATORIA_A_LA_LICITACI%C3%93N_P%C3%9ABLICA_pbc.pdf"
                    },
                    {
                        "nombre" : "19.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/19SITMAHLPNSL022022/BASES_DE_LICITACI%C3%93N_PUBLICA_NACIONAL_NO_SITMAH-LPN-SL-002-2022.pdf"
                    },
                    {
                        "nombre" : "19.3 Anexo Técnico",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/19SITMAHLPNSL022022/Anexo_t%C3%A9cnico_Licitaci%C3%B3n_Limpieza_SITMAH.pdf"
                    },
                    {
                        "nombre" : "19.4 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/19SITMAHLPNSL022022/ACTA_DE_JUNTA_DE_ACLARACIONES.pdf"
                    },
                    {
                        "nombre" : "19.5 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/19SITMAHLPNSL022022/Acta-de-Fallo-LPN-No-SITMAH-LPN-SL-002-2022.pdf"
                    }
                ]
            },
            {
                "nombre" : "20.-Servicio de Limpieza, Licitación Publica Nacional NO. SITMAH/LPN/SL/003/2022",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "20.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/20SITMAHLPNSL0032022/CONVOCATORIA-SITMAH-LPN-SL-003-2022.pdf"
                    },
                    {
                        "nombre" : "20.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/20SITMAHLPNSL0032022/Bases-de-Licitaci%C3%B3n-Publica-Nacional-SITMAH(Julio-Diciembre).pdf"
                    },
                    {
                        "nombre" : "20.3 Anexo Técnico",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/20SITMAHLPNSL0032022/Anexo-t%C3%A9cnico-Licitaci%C3%B3n-Limpieza-SITMAH-16-06-222.pdf"
                    }
                ]
            }
        ]
    },
    {
        "anio" : "2023",
        "licitacionesLista" : [
            {
                "nombre" : "21.-Servicio de Limpieza y Manejo de desechos, Licitación Publica Nacional NO. SITMAH/LPN/SLYMD/003/2023",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "21.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/21SITMAHLPNSL0032023/CONVOCATORIA-SITMAH-LPN-SLYMD-003-2023.pdf"
                    },
                    {
                        "nombre" : "21.2 Bases",
                        "url" : ""
                    },
                    {
                        "nombre" : "21.3 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/21SITMAHLPNSL0032023/ACTA_DE_JUNTA_DE_ACLARACIONES_SERVICIO_DE_LIMPIZA.pdf"
                    },
                    {
                        "nombre" : "21.4 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/21SITMAHLPNSL0032023/ACTA-DE-PRESENTACION-DE-PROPUESTAS-LICITACION-NO-SITMAH-LPNSLYMD-003-2023.pdf"
                    },
                    {
                        "nombre" : "21.5 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/21SITMAHLPNSL0032023/Acta_fallo.pdf"
                    }
                ]
            },
            {
                "nombre" : "22.- Contratación del Servicio de Arrendamiento de Equipo de Fotocopiado Nacional no. SITMAH/LPN/AEF/004/2023",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "22.1 Acta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/21SITMAHLPNSL0032023/ACTA-DE-ACLARACIONES-LICITACION-NO-SITMAH-LPN-AEF-0074-2023.pdf"
                    },
                    {
                        "nombre" : "22.2 CTA Nulida",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/21SITMAHLPNSL0032023/CTA-DE-NULIDAD-PROCEDIMIENTO-DE-LICITACION-NO-SITMAH-LPN-AEF-2023.pdf"
                    }
                ]
            },
            {
                "nombre" : "23.-Contratación del Servicio de Limpieza y Manejo de Desechos de las 34 estaciones, Ciclopuerto, C.G.G.O, Cetram, Oficinas Centrales del SITMAH y Oficinas del SITMAH en Tellez. No. SITMAH/LPN/SLyMD/005/2023",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "23.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/23-SITMAHLPNSLyMD0052023/CONVOCATORIA-SERVICIO-DE-LIMPIEZA-JULIO.pdf"
                    },
                    {
                        "nombre" : "23.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/23-SITMAHLPNSLyMD0052023/1-Bases-Lic-Limpieza-2023.pdf"
                    },
                    {
                        "nombre" : "23.3 Acta de Visita",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/23-SITMAHLPNSLyMD0052023/Acta_de_visita.pdf"
                    },
                    {
                        "nombre" : "23.4 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/23-SITMAHLPNSLyMD0052023/Acta_Junta_de_Aclaraciones.pdf"
                    },
                    {
                        "nombre" : "23.5 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/23-SITMAHLPNSLyMD0052023/Acta_presentacion_apertura.pdf"
                    },
                    {
                        "nombre" : "23.6 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/23-SITMAHLPNSLyMD0052023/Acta_fallo.pdf"
                    }
                ]
            },
            {
                "nombre" : "24.-Servicio de Arrendamiento de Equipo de Fotocopiado Nacional no. SITMAH/LPN/AEF/006/2023",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "24.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/24-SITMAHLPNAEF0062023/CONVOC_1.pdf"
                    },
                    {
                        "nombre" : "24.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/24-SITMAHLPNAEF0062023/1_Bases_Licitaci%C3%B3n_Equipo_de_Fotocopiado.pdf"
                    },
                    {
                        "nombre" : "24.3 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/24-SITMAHLPNAEF0062023/Acta_junta_aclaraciones.pdf"
                    },
                    {
                        "nombre" : "24.4 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/24-SITMAHLPNAEF0062023/Acta_Presentacion_apertura.pdf"
                    },
                    {
                        "nombre" : "24.5 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/24-SITMAHLPNAEF0062023/Acta_fallo.pdf"
                    }
                ]
            },
            {
                "nombre" : "25.-Servicio de aseguramiento de la Infraestructura Pública de Transporte de la Red Integrada del Corredor Uno de la Zona Metropolitana Nacional no. SITMAH/LPN/SA/2023",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "25.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/25-SITMAHLPNSA2023/CONVOC_1.pdf"
                    },
                    {
                        "nombre" : "25.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/25-SITMAHLPNSA2023/1_Bases.doc"
                    },
                    {
                        "nombre" : "25.3 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/25-SITMAHLPNSA2023/Acta_junta_aclaraciones.pdf"
                    },
                    {
                        "nombre" : "25.4 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/25-SITMAHLPNSA2023/Acta_Presentacion_apertura.pdf"
                    },
                    {
                        "nombre" : "25.5 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/25-SITMAHLPNSA2023/Acta_fallo.pdf"
                    }
                ]
            },
            {
                "nombre" : "26.- Aseguramiento de la infraestructura pública de la red de transporte del corredor uno de la zona metropolitana de la ciudad de pachuca de soto hidalgo, Licitación Pública Nacional no. SITMAH/LPN/008/2023.",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "26.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/26-SITMAHLPN0082023/CONVOC_1.pdf"
                    },
                    {
                        "nombre" : "26.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/26-SITMAHLPN0082023/1_Bases.doc"
                    },
                    {
                        "nombre" : "26.3 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/26-SITMAHLPN0082023/Acta_junta_aclaraciones.pdf"
                    },
                    {
                        "nombre" : "26.4 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/26-SITMAHLPN0082023/Acta_Presentacion_apertura.pdf"
                    },
                    {
                        "nombre" : "26.5 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/26-SITMAHLPN0082023/Acta_fallo.pdf"
                    }
                ]
            },
            {
                "nombre" : "27.- Aseguramiento de la infraestructura pública de la red de transporte del corredor uno de la zona metropolitana de la ciudad de pachuca de soto hidalgo, Licitación Pública Nacional no. SITMAH/LPN/SA/009/2023.",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "27.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/27-SITMAHLPNSA0092023/CONVOC_1.doc"
                    },
                    {
                        "nombre" : "27.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/27-SITMAHLPNSA0092023/1_Bases.docx"
                    },
                    {
                        "nombre" : "27.3 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/27-SITMAHLPNSA0092023/Acta_junta_aclaraciones.pdf"
                    },
                    {
                        "nombre" : "27.4 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/27-SITMAHLPNSA0092023/Acta_Presentacion_apertura.pdf"
                    },
                    {
                        "nombre" : "27.5 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/27-SITMAHLPNSA0092023/Acta_fallo.pdf"
                    }
                ]
            },
            {
                "nombre" : "28.- Contratacion del suministro de Motocicletas, Licitación Pública Nacional no. SITMAH/LPN/ET/010/2023.",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "28.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/28-SITMAHLPNET0102023/CONVOC_1.pdf"
                    },
                    {
                        "nombre" : "28.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/28-SITMAHLPNET0102023/1_Bases.docx"
                    }
                   /**  {
                        "nombre" : "27.3 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/27-SITMAHLPNSA0092023/Acta_junta_aclaraciones.pdf"
                    },
                    {
                        "nombre" : "27.4 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/27-SITMAHLPNSA0092023/Acta_Presentacion_apertura.pdf"
                    },
                    {
                        "nombre" : "27.5 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/27-SITMAHLPNSA0092023/Acta_fallo.pdf"
                    }*/
                ]
            }
        ]
    },
    {
        "anio" : "2024",
        "licitacionesLista" : [
            {
                "nombre" : "29.- Adjudicación del Servicio de Limpieza y Manejo de Desechos, Licitación Pública Nacional no. SITMAH/LPN/SLyMD/001/2024.",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "29.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/29-SITMAHLPNSLyMD0012024/CONVOC_1.pdf"
                    },
                    {
                        "nombre" : "29.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/29-SITMAHLPNSLyMD0012024/1_Bases.docx"
                    },
                   {
                        "nombre" : "29.3 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/29-SITMAHLPNSLyMD0012024/Acta_junta_aclaraciones.pdf"
                    },
                    {
                        "nombre" : "29.4 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/29-SITMAHLPNSLyMD0012024/Acta_Presentacion_apertura.pdf"
                    },
                    {
                        "nombre" : "29.5 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/29-SITMAHLPNSLyMD0012024/Acta_fallo.pdf"
                    }
                ]
            },
            {
                "nombre" : "30.- Contratación del servicio de limpieza y manejo de desechos para las 34 estaciones, Ciclopuerto, Oficinas Centrales en San Javier y Tellez, Licitación Pública Nacional no. SITMAH/LPN/SLyMD/003/2024.",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "30.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/30-SITMAHLPNSLyMD0032024/CONVOC_1.pdf"
                    },
                    {
                        "nombre" : "30.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/30-SITMAHLPNSLyMD0032024/1_Bases.docx"
                    },
                   {
                        "nombre" : "30.3 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/30-SITMAHLPNSLyMD0032024/Acta_junta_aclaraciones.pdf"
                    },
                    {
                        "nombre" : "30.4 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/30-SITMAHLPNSLyMD0032024/Acta_Presentacion_apertura.pdf"
                    },
                    {
                        "nombre" : "30.5 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/30-SITMAHLPNSLyMD0032024/Acta_fallo.pdf"
                    }
                ]
            },
            {
                "nombre" : "31  -Contratación de servicio de fotocopiado para este organismo, Licitación Pública Nacional no. SITMAH/LPN/AEF/002/2024.",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "31.1 -Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/31-SITMAHLPNAEF0022024/CONVOC_1.pdf"
                    },
                    {
                        "nombre" : "31.2 -Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/31-SITMAHLPNAEF0022024/1_Bases.docx"
                    },
                   {
                        "nombre" : "31.3 -Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/31-SITMAHLPNAEF0022024/Acta_junta_aclaraciones.pdf"
                    },
                    {
                        "nombre" : "31.4 -Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/31-SITMAHLPNAEF0022024/Acta_Presentacion_apertura.pdf"
                    },
                    {
                        "nombre" : "31.5 -Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/31-SITMAHLPNAEF0022024/Acta_fallo.pdf"
                    }
                ]
            },
            {
                "nombre" : "32.- Segunda Contratación de servicio de fotocopiado para este organismo, Licitación Pública Nacional no. SITMAH/LPN/AEF/004/2024.",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "31.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/32-SITMAHLPNAEF0042024/CONVOC_1.pdf"
                    },
                    {
                        "nombre" : "31.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/32-SITMAHLPNAEF0042024/1_Bases.DOC"
                    },
                   {
                        "nombre" : "31.3 *Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/32-SITMAHLPNAEF0042024/Acta_junta_aclaraciones.pdf"
                    },
                    {
                        "nombre" : "31.4 *Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/32-SITMAHLPNAEF0042024/Acta_Presentacion_apertura.pdf"
                    },
                    {
                        "nombre" : "31.5 *Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/licitaciones/32-SITMAHLPNAEF0042024/Acta_fallo.pdf"
                    }
                ]
            },
            {
                "nombre" : "33.- Contratación de la partida 214001 Materiales y Útiles Consumibles para el Procesamiento en equipo y bienes informáticos, Licitación Pública Nacional no. SITMAH/LPN/MYUCPEBI/005/2024.",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "33.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/33-SITMAHLPNMYUCPEBI0052024/CONVOCATORIA_A_LA_LICITACION_PUBLICA_NACIONAL.pdf"
                    },
                    {
                        "nombre" : "33.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/33-SITMAHLPNMYUCPEBI0052024/1_Bases_Materiales_Utiles_Consumibles_2024.pdf"
                    },
                   {
                        "nombre" : "33.3 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/33-SITMAHLPNMYUCPEBI0052024/Acta_junta_aclaraciones.pdf"
                    },
                    {
                        "nombre" : "33.4 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/33-SITMAHLPNMYUCPEBI0052024/Acta_Presentacion_apertura.pdf"
                    },
                    {
                        "nombre" : "33.5 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/33-SITMAHLPNMYUCPEBI0052024/Acta_fallo.pdf"
                    }
                ]
            },
            {
                "nombre" : "34.- Contratación de 4 Autobuses para Transporte Público con Elevador para Silla de Ruedas, Licitación Pública Nacional no. SITMAH/LPN/VYET/009/2024.",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "34.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/34-SITMAHLPNVYET0092024/CONVOCATORIA-A-LA-LICITACION-PUBLICA-NACIONAL-NO-SITMAH-LPN-VYET-009-2024.pdf"
                    },
                    {
                        "nombre" : "34.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/34-SITMAHLPNVYET0092024/1-BASES-DE-LICITACION-PUBLICA-NACIONAL-NO-SITMAH-LPN-VYET-009-2024.pdf"
                    },
                    {
                        "nombre" : "34.3 Anexo Técnico",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/34-SITMAHLPNVYET0092024/ANEXO-TÉCNICO-AUTOBUSES-y-ELEVADORES-RUTAS-DE-LOS-HOSPITALES-B.pdf"
                    },
                   {
                        "nombre" : "33.3 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/34-SITMAHLPNVYET0092024/Acta_junta_aclaraciones.pdf"
                    },
                    {
                        "nombre" : "33.4 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/34-SITMAHLPNVYET0092024/Acta_Presentacion_apertura.pdf"
                    },
                    {
                        "nombre" : "33.5 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/34-SITMAHLPNVYET0092024/Acta_fallo.pdf"
                    }
                ]
            },
            {
                "nombre" : "35.- Segunda Convocatoria para la adjudicación de las partidas 541001 Vehículos y Equipo Terrestre y la partida 542001 Carrocerias y Remolques, Licitación Pública Nacional no. SITMAH/LPN/VYET/010/2024.",
                "contenidoLicitacion" : [                    
                    {
                        "nombre" : "35.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/35-SITMAHLPNVYET0102024/CONVOCATORIA_A_LA_LICITACION_NO_SITMAH-LPN-VYET-010-2024.pdf"
                    },
                    {
                        "nombre" : "35.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/35-SITMAHLPNVYET0102024/1_BASES_DE_LICITACION_PUBLICA_NACIONAL_NO.SITMAH-LPN-VYET-010-2024_SEGUNDA_CONVOCATORIA.pdf"
                    },
                    {
                        "nombre" : "35.3 Anexo Técnico",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/35-SITMAHLPNVYET0102024/ANEXO_TÉCNICO_AUTOBUSES_y_ELEVADORES_RUTAS_DE_LOS_HOSPITALES_B.docx"
                    },
                   {
                        "nombre" : "35.4 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/35-SITMAHLPNVYET0102024/Acta_junta_aclaraciones.pdf"
                    },
                    {
                        "nombre" : "35.5 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/35-SITMAHLPNVYET0102024/Acta_Presentacion_apertura.pdf"
                    },
                    {
                        "nombre" : "35.6 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/35-SITMAHLPNVYET0102024/Acta_fallo.pdf"
                    }
                ]
            },
            {
                "nombre" : "36.- Convocatoria para la contratación del servicio de la partida de SEGUROS, Licitación Pública Nacional no. EA-913003989-N216-2024.",
                "contenidoLicitacion" : [                    
                    {
                        "nombre" : "36.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/36-EA913003989N2162024/CONVOCATORIA EA-913003989-N216-2024.docx"
                    },
                    {
                        "nombre" : "36.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/36-EA913003989N2162024/BASES EA-913003989-N216-2024.pdf"
                    }/*,
                    {
                        "nombre" : "36.3 Anexo Técnico",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/36-EA913003989N2162024/ANEXO_TÉCNICO_AUTOBUSES_y_ELEVADORES_RUTAS_DE_LOS_HOSPITALES_B.docx"
                    }*/,
                   {
                        "nombre" : "36.3 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/36-EA913003989N2162024/Acta_junta_aclaraciones.pdf"
                    },
                    {
                        "nombre" : "36.4 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/36-EA913003989N2162024/Acta_Presentacion_apertura.pdf"
                    },
                    {
                        "nombre" : "36.5 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/36-EA913003989N2162024/Acta_fallo.pdf"
                    }
                ]
            },
            {
                "nombre" : "37.- Convocatoria para la contratación 214001 Materiales y Útiles Consumibles para el Procesamiento en Equipos y Bienes Informáticos, Licitación Pública Nacional No. SITMAH/LPN/MYUCPEBI/006/2024.",
                "contenidoLicitacion" : [                    
                    {
                        "nombre" : "37.1 Convocatoria",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/37-SITMAHLPNMYUCPEBI0062024/Convocatoria_LPN_SITMAH-LPN-MYUCPEBI-006-2024.pdf"
                    },
                    {
                        "nombre" : "37.2 Bases",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/37-SITMAHLPNMYUCPEBI0062024/BASES_DE_LA_CONVOCATORIA_LPN_SITMAH-LPN-MYUCPEBI-006-2024.pdf"
                    }/*,
                    {
                        "nombre" : "36.3 Anexo Técnico",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/36-EA913003989N2162024/ANEXO_TÉCNICO_AUTOBUSES_y_ELEVADORES_RUTAS_DE_LOS_HOSPITALES_B.docx"
                    }*/,
                   {
                        "nombre" : "37.3 Acta Junta de Aclaraciones",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/37-SITMAHLPNMYUCPEBI0062024/Acta_junta_aclaraciones.pdf"
                    },
                    {
                        "nombre" : "37.4 Acta de Presentación y Apertura de Propuestas",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/37-SITMAHLPNMYUCPEBI0062024/Acta_Presentacion_apertura.pdf"
                    },
                    {
                        "nombre" : "37.5 Acta de fallo",
                        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/37-SITMAHLPNMYUCPEBI0062024/Acta_fallo.pdf"
                    }
                ]
            }
        ]
    }
]